import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VAutocomplete,{attrs:{"outlined":"","items":_vm.items,"label":_vm.field.text,"multiple":_vm.field.multiple,"item-text":_vm.textProperty,"item-value":"id","rules":_vm.validators,"placeholder":_vm.field.placeholder || '-',"append-icon":"mdi-chevron-down","dense":"","clearable":_vm.field.clearable},on:{"click:clear":_vm.clearValue},scopedSlots:_vm._u([(['vehicleId','terminalId', 'trailerId'].includes(_vm.field.name))?{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"w-100 d-flex justify-space-between"},[_vm._v(" "+_vm._s(item[_vm.textProperty])+" "),(item.driver)?_c('span',{staticClass:"error--text"},[_vm._v("("+_vm._s(item.driver.fullName)+")")]):_vm._e()])]}}:null,(_vm.field.multiple && _vm.field.inputVisibleItems)?{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('MultiselectChoices',{attrs:{"index":index,"label":item[_vm.textProperty],"array-length":_vm.valueTemp ? _vm.valueTemp.length : 0,"visible-items":_vm.field.inputVisibleItems,"max-length":_vm.field.valueMaxLength}})]}}:null],null,true),model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})}
var staticRenderFns = []

export { render, staticRenderFns }