<template>
  <v-autocomplete
    outlined
    :items="items"
    :label="field.text"
    :multiple="field.multiple"
    v-model="value"
    :item-text="textProperty"
    item-value="id"
    :rules="validators"
    :placeholder="field.placeholder || '-'"
    append-icon="mdi-chevron-down"
    dense
    :clearable="field.clearable"
    @click:clear="clearValue"
  >
    <template
      #item="{item}"
      v-if="['vehicleId','terminalId', 'trailerId'].includes(field.name)"
    >
      <div class="w-100 d-flex justify-space-between">
        {{ item[textProperty] }}
        <span
          v-if="item.driver"
          class="error--text"
        >({{ item.driver.fullName }})</span>
      </div>
    </template>
    <template
      v-if="field.multiple && field.inputVisibleItems"
      #selection="{ item, index }"
    >
      <MultiselectChoices
        :index="index"
        :label="item[textProperty]"
        :array-length="valueTemp ? valueTemp.length : 0"
        :visible-items="field.inputVisibleItems"
        :max-length="field.valueMaxLength"
      />
    </template>
  </v-autocomplete>
</template>

<script>
import inputMixin from '../../../mixins/InputMixin.vue'
import get from 'lodash/get'
import MultiselectChoices from '../../Elements/MultiselectChoices.vue'

export default {
  components: {
    MultiselectChoices
  },
  data() {
    return {
      valueTemp: null
    }
  },
  mixins: [inputMixin],
  mounted() {
    this.valueTemp = this.value
  },
  computed: {
    items () {
      const tableName = this.field.options
      return this.$store.state[tableName].items
    },
    textProperty () {
      return this.field.itemText || 'name'
    },
    value: {
      get () {
        const currentValue = get(this.data, this.field.value, '')
        const currentObject = this.items.find((item) => {
          return currentValue === item[this.textProperty]
        })
        return currentObject ? currentObject.id : null
      },
      set (value) {
        this.$emit('update', { [this.field.name]: value })
        this.valueTemp = value
      }
    }
  },
  methods: {
    clearValue () {
      this.$nextTick(() => { this.value = null })
    }
  }
}
</script>
